import {
  CartItemModelRequestDto,
  CartItemModelResponseDto,
  PriceTypeModelResponseDto,
  ProductModelResponseDto,
  SuborderModelResponseDto,
  SummaryProductResponseDto,
  SummaryResponseDto,
} from '../models';
import { DecimalPrecision2 } from './math.helper';

// Main function to calculate suborder price
export function calculateSuborderPrice(
  cartItems: CartItemModelRequestDto[],
  products: ProductModelResponseDto[],
  discountRate = 0,
): SummaryResponseDto {
  const summaryProducts: SummaryProductResponseDto[] = [];
  const summaryPrice = initializeSummaryPrice();

  if (!cartItems.length || !products.length) {
    return { products: summaryProducts, price: summaryPrice };
  }

  cartItems.forEach(cartItem => {
    const product = products.find(p => p._id === cartItem.product);
    addCartItemToSummary(cartItem, summaryProducts, product);
  });

  processSummaryProducts(summaryProducts, summaryPrice);

  if (discountRate > 0) {
    applyDiscountAndVAT(summaryPrice, discountRate);
  }

  return { products: summaryProducts, price: summaryPrice };
}

// Calculate overall summary for multiple suborders
export function calculateSummary(suborders: SuborderModelResponseDto[] | undefined): SummaryResponseDto {
  const summaryProducts: SummaryProductResponseDto[] = [];
  const summaryPrice = initializeSummaryPrice();

  suborders?.forEach(suborder => suborder.cartItems?.forEach(cartItem => addCartItemToSummary(cartItem, summaryProducts)));

  processSummaryProducts(summaryProducts, summaryPrice);

  return { products: summaryProducts, price: summaryPrice };
}

// Initialize summary price object
export function initializeSummaryPrice(): PriceTypeModelResponseDto {
  return {
    basePrice: 0,
    discountRate: 0,
    discountedPrice: 0,
    vatGroupId: -1,
    vatGroupPercentageValue: 0,
    vatAmount: 0,
    finalPrice: 0,
  };
}

// Add cart item to summary
function addCartItemToSummary(
  cartItem: CartItemModelRequestDto | CartItemModelResponseDto,
  summaryProducts: SummaryProductResponseDto[],
  product?: ProductModelResponseDto,
) {
  const { quantity, productPriceSnapshot } = cartItem;
  const { basePrice, discountedPrice, vatGroupPercentageValue } = productPriceSnapshot;
  const matchingProduct = summaryProducts.find(summaryProduct => isMatchingProduct(summaryProduct, product, basePrice, discountedPrice));
  const vatAmount = calculateVAT(discountedPrice, vatGroupPercentageValue || matchingProduct?.product.price.vatGroupPercentageValue || 0);
  const finalPrice = DecimalPrecision2.round(discountedPrice + vatAmount, 2);

  if (matchingProduct) {
    updateExistingSummaryProduct(matchingProduct, quantity, basePrice, discountedPrice, vatAmount, finalPrice);
  } else {
    summaryProducts.push(createNewSummaryProduct(cartItem, product, quantity, basePrice, discountedPrice, vatAmount, finalPrice));
  }
}

// Check if a product matches the summary product
function isMatchingProduct(
  summaryProduct: SummaryProductResponseDto,
  product?: ProductModelResponseDto,
  basePrice?: number,
  discountedPrice?: number,
): boolean {
  return (
    summaryProduct.product._id === product?._id &&
    summaryProduct.product.price.basePrice === basePrice &&
    summaryProduct.product.price.discountedPrice === discountedPrice
  );
}

// Create a new summary product
function createNewSummaryProduct(
  cartItem: CartItemModelRequestDto | CartItemModelResponseDto,
  product: ProductModelResponseDto | undefined,
  quantity: number,
  basePrice: number,
  discountedPrice: number,
  vatAmount: number,
  finalPrice: number,
): SummaryProductResponseDto {
  return {
    product: product || (cartItem.product as ProductModelResponseDto),
    quantity,
    total: {
      ...(product?.price || cartItem.productPriceSnapshot),
      basePrice: DecimalPrecision2.round(basePrice * quantity, 2),
      discountedPrice: DecimalPrecision2.round(discountedPrice * quantity, 2),
      vatAmount: DecimalPrecision2.round(vatAmount * quantity, 2),
      finalPrice: DecimalPrecision2.round(finalPrice * quantity, 2),
    },
  };
}

// Update an existing summary product
function updateExistingSummaryProduct(
  product: SummaryProductResponseDto,
  quantity: number,
  basePrice: number,
  discountedPrice: number,
  vatAmount: number,
  finalPrice: number,
) {
  product.quantity += quantity;
  product.total.basePrice = DecimalPrecision2.round(basePrice * product.quantity, 2);
  product.total.discountedPrice = DecimalPrecision2.round(discountedPrice * product.quantity, 2);
  product.total.vatAmount = DecimalPrecision2.round(vatAmount * product.quantity, 2);
  product.total.finalPrice = DecimalPrecision2.round(finalPrice * product.quantity, 2);
}

// Process all summary products to update summary price
function processSummaryProducts(summaryProducts: SummaryProductResponseDto[], summaryPrice: PriceTypeModelResponseDto) {
  summaryProducts.forEach(productSummary => calculateTotalFromProducts(productSummary, summaryPrice));
  roundSummaryPrices(summaryPrice);
}

// Calculate VAT for a price
function calculateVAT(price: number, vatRate: number): number {
  return DecimalPrecision2.round((price * vatRate) / 100, 2);
}

// Apply discount and VAT to summary price
function applyDiscountAndVAT(summaryPrice: PriceTypeModelResponseDto, discountRate: number) {
  const discountMultiplier = 1 - discountRate / 100;
  summaryPrice.discountedPrice = DecimalPrecision2.round(summaryPrice.finalPrice * discountMultiplier, 2);
  summaryPrice.vatAmount = DecimalPrecision2.round((summaryPrice.discountedPrice * (summaryPrice.vatGroupPercentageValue ?? 0)) / 100, 2);
  summaryPrice.finalPrice = DecimalPrecision2.round(summaryPrice.discountedPrice + summaryPrice.vatAmount, 2);
}

// Helper to calculate total from product and add to summary price
function calculateTotalFromProducts(productSummary: SummaryProductResponseDto, summaryPrice: PriceTypeModelResponseDto) {
  const { quantity, total } = productSummary;

  total.basePrice = productSummary.product.price.basePrice * quantity;
  total.discountedPrice = productSummary.product.price.discountedPrice * quantity;
  total.vatAmount = productSummary.product.price.vatAmount * quantity;
  total.finalPrice = productSummary.product.price.finalPrice * quantity;

  sumUpSummaryPrices(summaryPrice, total);
}

// Accumulate totals for summary price
function sumUpSummaryPrices(summaryPrice: PriceTypeModelResponseDto, total: PriceTypeModelResponseDto) {
  summaryPrice.basePrice += total.basePrice;
  summaryPrice.discountedPrice += total.discountedPrice;
  summaryPrice.vatAmount += total.vatAmount;
  summaryPrice.finalPrice += total.finalPrice;
}

// Finalize summary price by rounding
function roundSummaryPrices(summaryPrice: PriceTypeModelResponseDto) {
  summaryPrice.basePrice = DecimalPrecision2.round(summaryPrice.basePrice, 2);
  summaryPrice.discountedPrice = DecimalPrecision2.round(summaryPrice.discountedPrice, 2);
  summaryPrice.vatAmount = DecimalPrecision2.round(summaryPrice.vatAmount, 2);
  summaryPrice.finalPrice = DecimalPrecision2.round(summaryPrice.finalPrice, 2);
}
