import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { debounce as debounceFn } from 'lodash-es';
import { PaginationDTO } from '../interfaces';
import { AppLinksTypeModel, DaysEnum, PagesEnum, WaytrBaseIdModel } from '../models';
import { DecimalPrecision2 } from './math.helper';

export function updateLinks(links: AppLinksTypeModel[], routeParams: Params | undefined): AppLinksTypeModel[] {
  const newLinks = links;
  newLinks.forEach(link => {
    link.route = buildRoute(routeParams, link.defaultRoute);
  });

  return newLinks;
}

export const nonNullObject = <T extends object>(objectToTest: object): objectToTest is T =>
  Object.values(objectToTest).every(value => value !== null);

export function buildRoute(routeParams: Params | undefined, route: string): string {
  if (routeParams?.sessionUrl) {
    return `${routeParams?.venue}/${routeParams?.table}/${routeParams?.sessionUrl}/${route}`;
  } else {
    return `${routeParams?.venue}/${routeParams?.table}/${route}`;
  }
}

export function stopPropagation(event: MouseEvent | TouchEvent | KeyboardEvent) {
  event.stopImmediatePropagation();
}

/**
 * Debounce a method
 */
export function debounce(milliseconds = 0, options = {}) {
  return function (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = debounceFn(originalMethod, milliseconds, options);
    return descriptor;
  };
}

export function isSessionUrlAPredefinedPage(sessionUrl: string): boolean {
  return Object.values(PagesEnum)?.includes(sessionUrl as PagesEnum);
}

export function validateRouteParams(paramsString: string): boolean {
  const params = paramsString?.split('/') || '';
  if (params.length === 5) {
    return Object.values(PagesEnum)?.includes(params[4] as PagesEnum);
  }

  return false;
}

export function minutesDiff(date: string): number {
  const timestamp = new Date(date);
  const currentTime = new Date();
  const diffInMs = currentTime.getTime() - timestamp.getTime();
  const diffInMins = Math.round(diffInMs / (1000 * 60));
  return diffInMins;
}

export function invalidateForm(form: FormGroup) {
  Object.values(form.controls)?.forEach(control => {
    if (control.invalid) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
}

export function getToday<T>(type: T, day: number): T[keyof T] {
  const casted = day as keyof T;
  return type[casted];
}

export function toPresentationCase(sentence: string | undefined | null): string {
  if (!sentence) return '';
  return sentence[0].toUpperCase() + sentence.substring(1);
}

export function getTime(date: Date) {
  if (!date) return '';

  // Get the hours and minutes in 12-hour format (AM/PM)
  const hours = date.getHours() % 12;
  const minutes = date.getMinutes();

  // Determine whether it's AM or PM
  const period = date.getHours() >= 12 ? 'PM' : 'AM';

  // Format the time string
  return `${hours === 0 ? 12 : hours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;
}

export function getTimeAsDdMmYyyy(): string {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}

export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export function shiftSetValuesUp(set: Set<number>): Set<number> {
  const incrementedSet = new Set<number>();

  for (const value of set) {
    incrementedSet.add(value + 1);
  }

  return incrementedSet;
}

export function toggleExpandSet(expandSet: Set<number>, index: number) {
  if (expandSet.has(index)) {
    expandSet.delete(index);
  } else {
    expandSet.add(index);
  }
}

export const compareModelsFn = <T extends WaytrBaseIdModel>(p1: T, p2: T): boolean => (p1 && p2 ? p1._id === p2._id : p1 === p2);

export function getDynamicBoxShadow(color: string | undefined): string {
  return `0 0.8rem 1.2rem -0.4rem ${(color || '#6d757e') + '40'}`;
}

export function getDaysNames(days: DaysEnum[] | number[]): string {
  let daysAsNumbers: number[] = [];

  if (typeof days[0] === 'number') {
    daysAsNumbers = days as number[];
  } else {
    daysAsNumbers = (days as DaysEnum[]).map((val: DaysEnum) => Object.keys(DaysEnum).indexOf(val));
  }

  if (days.length === 7) {
    return 'All week';
  }

  if (daysAsNumbers.length === 0) {
    return 'No days selected';
  }

  const sortedDays = daysAsNumbers.sort((a, b) => a - b);
  const dayRanges = [];
  let currentRange = [sortedDays[0]];

  for (let i = 1; i < sortedDays.length; i++) {
    if (sortedDays[i] === sortedDays[i - 1] + 1) {
      currentRange.push(sortedDays[i]);
    } else {
      dayRanges.push(currentRange);
      currentRange = [sortedDays[i]];
    }
  }

  dayRanges.push(currentRange);

  const dayRangesFormatted = dayRanges.map(range => {
    if (range.length === 1) {
      return toPresentationCase(Object.values(DaysEnum)[range[0]]);
    } else {
      return `${toPresentationCase(Object.values(DaysEnum)[range[0]])} - ${toPresentationCase(Object.values(DaysEnum)[range[range.length - 1]])}`;
    }
  });

  return dayRangesFormatted.join(', ');
}

function hexToHSLValue(hex: string): { h: number; s: number; l: number } {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find greatest and smallest channel values
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  const l = (max + min) / 2;
  let h, s;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h! /= 6;
  }

  return {
    h: Math.round(h! * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}

export function hexToHSL(hex: string): string {
  // Convert hex to HSL
  const { h, s, l } = hexToHSLValue(hex);

  // Return HSLA string
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export function hexToHSLA(hex: string, opacity: number): string {
  // Convert hex to HSL
  const { h, s, l } = hexToHSLValue(hex);

  // Return HSLA string
  return `hsla(${h}, ${s}%, ${l}%, ${opacity})`;
}

export const retryUntil = (checkFn: () => boolean, successCallback: () => void, failCallback: () => void, maxRetries = 5, delay = 1000) => {
  let retries = 0;

  const interval = setInterval(() => {
    if (checkFn()) {
      clearInterval(interval);
      successCallback();
    } else {
      retries++;
      if (retries >= maxRetries) {
        clearInterval(interval);
        failCallback();
      }
    }
  }, delay);
};

export function isPaginationResponseDto<T>(response: any): response is PaginationDTO<T> {
  return response && typeof response.total === 'number' && typeof response.page === 'number' && typeof response.limit === 'number';
}

export function mergeNewUpdates<T extends WaytrBaseIdModel>(
  existingPagination: PaginationDTO<T> | undefined,
  updates: T[],
): PaginationDTO<T> {
  if (existingPagination) {
    return {
      ...existingPagination,
      total: existingPagination.total + updates.length,
      data: [...updates, ...existingPagination.data],
    };
  }

  return {
    data: updates,
    total: updates.length,
    page: 1,
    limit: updates.length,
  };
}

export function mergeUpdatesToInitialPaginationData<T extends WaytrBaseIdModel>(
  existingPagination: PaginationDTO<T> | undefined,
  updates: T[],
): PaginationDTO<T> {
  // Initialize data map from existing pagination data or empty map if undefined
  const dataMap = new Map<string, T>(existingPagination ? existingPagination.data.map(item => [item._id, item]) : []);

  // Merge updates into the data map
  updates.forEach(update => {
    const isDeleted = (update as any).deleted;
    const existsInMap = dataMap.has(update._id);

    if (existsInMap && isDeleted) {
      // Remove deleted item
      dataMap.delete(update._id);
    } else if (existsInMap) {
      // Update existing item
      dataMap.set(update._id, update);
    } else {
      // Add new item with a 'new' flag
      const newUpdate = { ...update, new: true }; // Use object spread to add 'new' flag safely
      dataMap.set(newUpdate._id, newUpdate);
    }
  });

  // Convert map values to an array for final output
  const updatedData = Array.from(dataMap.values());

  return {
    data: updatedData,
    total: updatedData.length,
    page: 1,
    limit: updatedData.length,
  };
}

export const computeVatFromTotalPrice = (totalPrice: number, vatPercentage: number) => {
  return DecimalPrecision2.round(totalPrice - totalPrice / (1 + vatPercentage / 100), 2);
};

export function mergeUpdatesToInitialData<T extends WaytrBaseIdModel>(existingData: T[] | undefined, updates: T[]): T[] {
  // Initialize data map from existing data or empty map if undefined
  const dataMap = new Map<string, T>(existingData ? existingData.map(item => [item._id, item]) : []);

  // Merge updates into the data map
  updates.forEach(update => {
    const isDeleted = (update as any).deleted;
    const existsInMap = dataMap.has(update._id);

    if (existsInMap && isDeleted) {
      // Remove deleted item
      dataMap.delete(update._id);
    } else if (existsInMap) {
      // Update existing item
      dataMap.set(update._id, update);
    } else {
      // Add new item with a 'new' flag
      const newUpdate = { ...update, new: true }; // Use object spread to add 'new' flag safely
      dataMap.set(newUpdate._id, newUpdate);
    }
  });

  // Convert map values to an array for final output
  const updatedData = Array.from(dataMap.values());

  return updatedData;
}

export function areUpdatesValid<T extends WaytrBaseIdModel>(updates: T[] | undefined): boolean {
  return Array.isArray(updates) && updates.length > 0;
}
