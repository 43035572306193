import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, contentChild } from '@angular/core';

@Component({
  selector: 'app-basic-component',
  imports: [CommonModule],
  templateUrl: './app-basic.component.html',
  styleUrls: ['./app-basic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBasicComponent {
  template = contentChild<TemplateRef<unknown>>('template');

  get context() {
    return this;
  }
}
