import { DataItem } from '@swimlane/ngx-charts';
import { eachDayOfInterval, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subMonths, subWeeks } from 'date-fns';
import { ReportsPeriodEnum } from '../models';

export function fillInGaps(series: DataItem[], period: ReportsPeriodEnum, mode: 'fullRange' | 'toCurrentTime'): DataItem[] {
  if (!series.length) return [];

  if (period === ReportsPeriodEnum.DAY) {
    const lastHour = mode === 'toCurrentTime' ? format(new Date(), 'HH:mm') : '23:00';
    return fillInGapsForDays(series, lastHour);
  } else if (period === ReportsPeriodEnum.WEEK) {
    const endDay = mode === 'toCurrentTime' ? new Date() : endOfWeek(new Date(series[0].name));
    return fillInGapsForWeek(series, endDay);
  } else if (period === ReportsPeriodEnum.MONTH) {
    const endDay = mode === 'toCurrentTime' ? new Date() : endOfMonth(new Date(series[0].name));
    return fillInGapsForMonth(series, endDay);
  }

  return [];
}

export function fillInGapsForDays(series: DataItem[], lastHour: string): DataItem[] {
  const seriesWithoutGaps: DataItem[] = [];
  const existingTimes = series.map(s => s.name);
  const lastHourNumber = Number(lastHour.slice(0, 2));

  for (let i = 0; i <= lastHourNumber; i++) {
    const referenceTime = `${i.toString().padStart(2, '0')}:00`;
    if (existingTimes.includes(referenceTime)) {
      const index = series.findIndex(s => s.name === referenceTime);
      seriesWithoutGaps.push(series[index]);
    } else {
      seriesWithoutGaps.push({ name: referenceTime, value: 0 });
    }
  }

  return seriesWithoutGaps;
}

export function fillInGapsForWeek(series: DataItem[], endDay: Date): DataItem[] {
  const seriesWithoutGaps: DataItem[] = [];
  const existingTimes = series.map(s => s.name);

  eachDayOfInterval({ start: startOfWeek(new Date(endDay)), end: endDay }).forEach(date => {
    const referenceTime = format(date, 'yyyy-MM-dd');
    if (existingTimes.includes(referenceTime)) {
      const index = series.findIndex(s => s.name === referenceTime);
      seriesWithoutGaps.push(series[index]);
    } else {
      seriesWithoutGaps.push({ name: referenceTime, value: 0 });
    }
  });

  return seriesWithoutGaps;
}

export function fillInGapsForMonth(series: DataItem[], endDay: Date): DataItem[] {
  const seriesWithoutGaps: DataItem[] = [];
  const existingTimes = series.map(s => s.name);

  eachDayOfInterval({ start: startOfMonth(new Date(endDay)), end: endDay }).forEach(date => {
    const referenceTime = format(date, 'yyyy-MM-dd');
    if (existingTimes.includes(referenceTime)) {
      const index = series.findIndex(s => s.name === referenceTime);
      seriesWithoutGaps.push(series[index]);
    } else {
      seriesWithoutGaps.push({ name: referenceTime, value: 0 });
    }
  });

  return seriesWithoutGaps;
}

export function generateDummySeries(period: ReportsPeriodEnum, timePeriod: 'current' | 'previous'): DataItem[] {
  if (period === ReportsPeriodEnum.DAY) {
    const name = timePeriod === 'current' ? format(new Date(), 'HH:mm') : '00:00';
    return [{ name, value: 0 }];
  } else if (period === ReportsPeriodEnum.WEEK) {
    const name = timePeriod === 'current' ? format(new Date(), 'yyyy-MM-dd') : format(subWeeks(new Date(), 1), 'yyyy-MM-dd');
    return [{ name, value: 0 }];
  } else if (period === ReportsPeriodEnum.MONTH) {
    const name = timePeriod === 'current' ? format(new Date(), 'yyyy-MM-dd') : format(subMonths(new Date(), 1), 'yyyy-MM-dd');
    return [{ name, value: 0 }];
  }

  return [];
}
