import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, WritableSignal, input, signal } from '@angular/core';
import { addOrRemoveAnimation } from 'src/app/animations';

@Component({
  selector: 'app-menu-action',
  imports: [CommonModule],
  templateUrl: './app-menu-action.component.html',
  styleUrls: ['./app-menu-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [addOrRemoveAnimation],
})
export class AppMenuActionComponent {
  title = input<string>('');
  icon = input<string>('');
  details = input<string>('');
  animation: WritableSignal<boolean> = signal(false);

  startAnimation() {
    this.animation.set(true);

    setTimeout(() => {
      this.animation.set(false);
    }, 1000);
  }
}
